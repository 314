import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { Button, Box, CssBaseline, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function ProductTable(props) {
  const { t } = useTranslation()

  const onClickProduct = (product_guid) => {
    props?.selectHardware(product_guid)
  }

  const onClickCancel = () => {
    props?.returnToCustomer()
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>{t("sku_code")}</TableCell>
            <TableCell>{t("product_name")}</TableCell>
              <TableCell align="right">{t("sales_price_excl_vat")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!props?.data || props?.data?.length === 0) &&
              <TableRow>
                  <TableCell>{t("no_products_found")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
              </TableRow>
            }
            {props?.data?.map((item, index) => (
              <TableRow key={index} onClick={() => onClickProduct(item.id)} onKeyDown={() => onClickProduct(item.id)} hover={true} sx={{cursor: "pointer"}}>
                  <TableCell>{item.product_sku_code}</TableCell>
                {item.product_name ? (
                  <TableCell>{item.product_name}</TableCell>
                ) : (
                  <TableCell>{t("product_without_name")}</TableCell>
                )}
                <TableCell align="right">{item.product_price_excl_vat.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ height: '37px', mt: 1, mb: 1 }}>
          <Button
            type="submit"
            variant="contained"
            color='secondary'
            onClick={() => onClickCancel()}
            sx={{ borderRadius: '9px', position: 'relative', float: 'left'}}
          >
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  )
}