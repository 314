import { StyleSheet } from '@react-pdf/renderer'
import { Font } from '@react-pdf/renderer'
import OpenSans from '../static/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../static/fonts/OpenSans-Bold.ttf'

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSans
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold'
    }
  ]
})

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '20px',
    paddingBottom: '60px'
  },
  header_logo: {
    width: '250px',
    height: '120px',
    margin: '30px 0 0 300px'
  },
  footer: {
    fontSize: '12pt',
    fontFamily: 'OpenSans',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    right: '20px'
  },
  footer_left: {
    width: '50%'
  },
  footer_right: {
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  index_table: {
    fontFamily: 'OpenSans',
    marginLeft: '40px'
  },
  index_header: {
    fontSize: '18pt',
    textDecoration: 'underline',
    marginBottom: '10px'
  },
  index_line: {
    fontSize: '12pt',
    fontWeight: 'bold',
    display: 'inline-block',
    textDecoration: 'none',
    color: '#000'
  },
  page_title: {
    fontSize: '18pt',
    flexDirection: 'row'
  },
  page_table: {
    fontFamily: 'OpenSans',
    marginLeft: '25px'
  },
  header: {
    fontSize: '10pt',
    flexDirection: 'row',
    marginTop: '10px'
  },
  header_170_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '170px'
  },
  header_350_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '350px'
  },
  line: {
    fontSize: '10pt',
    flexDirection: 'row'
  },
  line_170_L: {
    marginLeft: '5px',
    width: '170px'
  },
  line_350_L: {
    marginLeft: '5px',
    width: '350px'
  }
})