import i18n from "i18next"
import { store } from './redux/store'
import { initReactI18next } from "react-i18next"

const locale = navigator.language.split('-')[0]

store.languages = ["en","nl"]
var resources = {}

store.languages.forEach((language) => { resources[language] = { translation: require(`./translations/` + language + `.json`) }})

i18n
  .use(initReactI18next)
  .init({
    lng: locale,
    debug: process.env.REACT_APP_TRANSLATE_DEBUG === 'true',
    resources: resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

export default i18n
