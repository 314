import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hash_customers: {},
  hash_subscriptions: {},
  hash_listSettings: {},
  hash_products: {},
  hash_hardware: {},
  hash_employees: {},
  hash_cashDrawers: {},
  hash_quoteConditions: {}
}

export const inventarisationSlice = createSlice({
  name: 'inventarisation',
  initialState,
  reducers: {
    setAllUserSettings: (state, action) => {
      state.allUserSettings = action.payload
    },
    clearAllUserSettings: (state) => {
      state.allUserSettings = undefined
    },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload
    },
    clearUserSettings: (state) => {
      state.userSettings = undefined
    },
    setCustomers: (state, action) => {
      state.customers = action.payload.items
      action.payload.items.forEach(customer => {
        state.hash_customers[customer.id] = customer
      })
    },
    clearCustomers: (state) => {
      state.customers= undefined
      state.hash_customers = {}
    },
    setCustomer: (state, action) => {
      state.customer = action.payload
    },
    clearCustomer: (state) => {
      state.customer= undefined
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload
      action.payload.forEach(subscription => {
        state.hash_subscriptions[subscription.id] = subscription
      })
    },
    clearSubscriptions: (state) => {
      state.subscriptions= undefined
      state.hash_subscriptions = {}
    },
    setListSettings: (state, action) => {
      state.listSettings = action.payload.items
      action.payload.items.forEach(listSetting => {
        state.hash_listSettings[listSetting.id] = listSetting
      })
    },
    clearListSettings: (state) => {
      state.listSettings= undefined
      state.hash_listSettings = {}
    },
    setProducts: (state, action) => {
      state.products = action.payload
      action.payload.forEach(product => {
        state.hash_products[product.id] = product
      })
    },
    clearProducts: (state) => {
      state.products= undefined
      state.hash_products = {}
    },
    setHardware: (state, action) => {
      state.hardware = action.payload.items
      action.payload.items.forEach(hardware => {
        state.hash_hardware[hardware.id] = hardware
      })
    },
    clearHardware: (state) => {
      state.hardware= undefined
      state.hash_hardware = {}
    },
    setSuppliers: (state, action) => {
      state.suppliers = action.payload
    },
    clearSuppliers: (state) => {
      state.suppliers= undefined
    },
    setEmployees: (state, action) => {
      state.employees = action.payload.items
      action.payload.items.forEach(employee => {
        state.hash_employees[employee.id] = employee
      })
    },
    clearEmployees: (state) => {
      state.employees= undefined
      state.hash_employees = {}
    },
    setCashDrawers: (state, action) => {
      state.cashDrawers = action.payload.items
      action.payload.items.forEach(cashDrawer => {
        state.hash_cashDrawers[cashDrawer.id] = cashDrawer
      })
    },
    clearCashDrawers: (state) => {
      state.cashDrawers= undefined
      state.hash_cashDrawers = {}
    },
    setQuoteConditions: (state, action) => {
      state.quoteConditions = action.payload.items
      action.payload.items.forEach(quoteCondition => {
        state.hash_quoteConditions[quoteCondition.id] = quoteCondition
      })
    },
    clearQuoteConditions: (state) => {
      state.quoteConditions= undefined
      state.hash_quoteConditions = {}
    }
  },
})

export const { setUserSettings, clearUserSettings, setAllUserSettings, clearAllUserSettings, setCustomers, clearCustomers, setCustomer, clearCustomer, setSubscriptions, clearSubscriptions, setListSettings, clearListSettings, setProducts, clearProducts, setHardware, clearHardware, setSuppliers, clearSuppliers, setEmployees, clearEmployees, setCashDrawers, clearCashDrawers, setQuoteConditions, clearQuoteConditions } = inventarisationSlice.actions

export const getUserSettings = (state) => state.inventarisation.userSettings
export const getAllUserSettings = (state) => state.inventarisation.allUserSettings
export const getCustomers = (state) => state.inventarisation.customers
export const getHashCustomers = (state) => state.inventarisation.hash_customers
export const getCustomer = (state) => state.inventarisation.customer
export const getSubscriptions = (state) => state.inventarisation.subscriptions
export const getHashSubscriptions = (state) => state.inventarisation.hash_subscriptions
export const getListSettings = (state) => state.inventarisation.listSettings
export const getHashListSettings = (state) => state.inventarisation.hash_listSettings
export const getProducts = (state) => state.inventarisation.products
export const getHashProducts = (state) => state.inventarisation.hash_products
export const getHardware = (state) => state.inventarisation.hardware
export const getHashHardware = (state) => state.inventarisation.hash_hardware
export const getSuppliers = (state) => state.inventarisation.suppliers
export const getEmployees = (state) => state.inventarisation.employees
export const getHashEmployees = (state) => state.inventarisation.hash_employees
export const getCashDrawers = (state) => state.inventarisation.cashDrawers
export const getHashCashDrawers = (state) => state.inventarisation.hash_cashDrawers
export const getQuoteConditions = (state) => state.inventarisation.quoteConditions
export const getHashQuoteConditions = (state) => state.inventarisation.hash_quoteConditions

export default inventarisationSlice.reducer