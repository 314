import i18n from "i18next"

export function TranslateCustom(field, default_value = "") {
    if(field?.[i18n.language] !== undefined) {
        return field[i18n.language]
    }
    else if(typeof field === 'string' && field !== "") {
        return i18n.t(field)
    }
    else {
        return i18n.t(default_value)
    }
}