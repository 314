import Card from "../components/card"
import DashboardPanel from "../components/dashboardPanel"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getUserSettings, setUserSettings } from "../redux/dataslices/inventarisationSlice"
import { GetUserSettings } from "../helpers/dataHandlers/Inventarisation"

function Dashboard() {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const userSettings = useSelector(getUserSettings)
  const [userSettingsData, setUserSettingsData] = useState()

  const dashboardCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'dashboard',
    loading: loading,
    loadingText: "loading_dashboard",
    data: <DashboardPanel  
      id_owner={token.payload.sub}
      userSettingsData={userSettingsData}
      />
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      GetUserSettings(token)
        .then(response => { dispatch(setUserSettings(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line
  }, [token])
    
  useEffect(() => {
    if (!userSettings) return
    setLoading(true)
    setUserSettingsData(userSettings)

    // eslint-disable-next-line
  }, [userSettings])

  useEffect(() => {
    if (userSettingsData && userSettingsData !== null) {
      setLoading(false)
    }
  }, [userSettingsData])

  return (
      <>
        <Card {...dashboardCardProps} />
      </>
  )
}

export default Dashboard
