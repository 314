import Moment from 'moment'
import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { Button, Box, CssBaseline, Table, TableBody, TableCell, TableHead, TableRow, Slider, Switch } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function CustomersTable(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onClickCustomer = (customer_guid) => {
    navigate("/customer/".concat(customer_guid,"/1"))
  }

  const onClickReturnToDashboard = () => {
    navigate("/dashboard")
  }
  
  const handleChanceOfSuccessChange = id => (event, value) => {
    event.stopPropagation()
    props?.setChanceOfSuccess(id, value)
  }

  const handleChanceOfSuccessSave = id => (event) => {
    event.stopPropagation()
    props?.saveChanceOfSuccess(id)
  }

  const handleActiveChange = id => (event) => {
    props?.setActive(id, event.currentTarget.checked)
  }

  const doNothing = (event) => {
    event.stopPropagation()
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("customer_name")}</TableCell>
              <TableCell align="right">{t("address")}</TableCell>
              <TableCell align="right">{t("zipcode")}</TableCell>
              <TableCell>{t("place")}</TableCell>
              <TableCell>{t("one_off_costs")}</TableCell>
              <TableCell>{t("recurring_costs")}</TableCell>
              <TableCell>{t("creation_date")}</TableCell>
              <TableCell>{t("chance_of_success")}</TableCell>
              <TableCell>{t("active")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!props?.data || props?.data?.length === 0) &&
              <TableRow>
                  <TableCell colSpan="9">{t("no_customers_found")}</TableCell>
              </TableRow>
            }
            {props?.data?.map((item, index) => (
              <TableRow key={index} onClick={() => onClickCustomer(item.id)} onKeyDown={() => onClickCustomer(item.id)} hover={true} sx={{cursor: "pointer"}}>
                <TableCell>{item.relation_name ? item.relation_name : t("customer_without_name")}</TableCell>
                <TableCell align="right">{item.relation_address}</TableCell>
                <TableCell align="right">{item.relation_zipcode}</TableCell>
                <TableCell>{item.relation_place}</TableCell>
                <TableCell>{item.total_one_off_costs_excl_vat !== undefined?(Number)(item.total_one_off_costs_excl_vat).toFixed(2):""}</TableCell>
                <TableCell>{item.total_recurring_costs_excl_vat !== undefined?(Number)(item.total_recurring_costs_excl_vat).toFixed(2):""}</TableCell>
                <TableCell>{Moment(item.creation).format('DD-MM-YYYY')}</TableCell>
                <TableCell>
                  <Slider
                    onClick={doNothing}
                    onKeyDown={doNothing}
                    onChange={handleChanceOfSuccessChange(String(index))}
                    onBlur={handleChanceOfSuccessSave(String(index))}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={100}
                    value={item.chance_of_success}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    onClick={doNothing}
                    onChange={handleActiveChange(String(index))}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={item.active === true ? true : false}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ height: '37px', mt: 1, mb: 1, textAlign: 'center', position: 'relative' }}>
        <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => onClickReturnToDashboard()}
          sx={{ borderRadius: '9px', position: 'absolute', left: 0}}
        >
          {t('return_to_dashboard')}
        </Button>
      </Box>
    </ThemeProvider>
  )
}