import Moment from 'moment'
import { ThemeProvider } from '@emotion/react'
import { useState, Fragment } from "react"
import mainTheme from '../css/themes/mainTheme'
import { Button, Box, CssBaseline, Table, TableBody, TableCell, TableHead, TableRow, Slider, Switch } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function AllCustomersTable(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const token = useSelector(getToken)
  const [deleteAlertOpened, setDeleteAlertOpened] = useState(false);
  const [deleteCustomerGuid, setDeleteCustomerGuid] = useState("");
  const [deleteCustomerName, setDeleteCustomerName] = useState("");
  const [deleteOwnerGuid, setDeleteOwnerGuid] = useState("");

  const onClickCustomer = (customer_guid, owner_guid = null) => {
    let base_url = "/customer/".concat(customer_guid, "/1")
    if(!owner_guid || owner_guid === token.payload.sub) {
      navigate(base_url)
    }
    else {
      navigate(base_url.concat("/", owner_guid))
    }
  }

  const onClickOverviewButton = (event, customer_guid, owner_guid = null) => {
    event.stopPropagation()
    let base_url = "/overview/".concat(customer_guid)
    if(!owner_guid || owner_guid === token.payload.sub) {
      window.open(base_url, '_blank')?.focus()
    }
    else {
      window.open(base_url.concat("/", owner_guid), '_blank')?.focus()
    }
  }

  const onClickDeleteButton = (event, customer_name, customer_guid, owner_guid) => {
    event.stopPropagation()
    setDeleteCustomerName(customer_name)
    setDeleteCustomerGuid(customer_guid)
    setDeleteOwnerGuid(owner_guid)
    setDeleteAlertOpened(true)
  }

  const onClickReturnToDashboard = () => {
    navigate("/dashboard")
  }
  
  const deleteAlertCancel = () => {
    setDeleteAlertOpened(false)
  }

  const deleteAlertConfirm = () => {
    setDeleteAlertOpened(false)
    props?.deleteCustomer(deleteCustomerGuid, deleteOwnerGuid)
  }
  
  const handleChanceOfSuccessChange = (id_owner, id_record) => (event, value) => {
    event.stopPropagation()
    props?.setChanceOfSuccess(id_owner, id_record, value)
  }

  const handleChanceOfSuccessSave = (id_owner, id_record) => (event) => {
    event.stopPropagation()
    props?.saveChanceOfSuccess(id_owner, id_record)
  }

  const handleActiveChange = (id_owner, id_record) => (event) => {
    props?.setActive(id_owner, id_record, event.currentTarget.checked)
  }

  const doNothing = (event) => {
    event.stopPropagation()
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box>
        <Dialog
          open={deleteAlertOpened}
          onClose={deleteAlertCancel}
          >
          <DialogTitle>
            {t("delete_customer")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("delete_customer_message",{name: deleteCustomerName})}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteAlertCancel} autoFocus>{t("cancel")}</Button>
            <Button onClick={deleteAlertConfirm}>{t("delete_customer")}</Button>
          </DialogActions>
        </Dialog>

        <Table>
          {(!props?.userSettingsData || props.userSettingsData.length === 0) ?
            <TableHead>
              <TableRow>
                <TableCell colSpan="11">
                  {t("no_users_found")}
                </TableCell>
              </TableRow>
            </TableHead>
          : props.userSettingsData.map((owner, owner_index) => (
            <Fragment
              key={owner_index}
              >
              <TableHead>
                <TableRow>
                  <TableCell colSpan="11">
                    {t("customers_brought_on_by",{name: owner.user_name ? owner.user_name + " (" + (owner.user_email ? owner.user_email : t("unknown_email")) + ")": t("unknown_name") + " (" + owner.id_owner + ")"})}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>{t("customer_name")}</TableCell>
                  <TableCell align="right">{t("address")}</TableCell>
                  <TableCell align="right">{t("zipcode")}</TableCell>
                  <TableCell>{t("place")}</TableCell>
                  <TableCell>{t("one_off_costs")}</TableCell>
                  <TableCell>{t("recurring_costs")}</TableCell>
                  <TableCell>{t("creation_date")}</TableCell>
                  <TableCell>{t("chance_of_success")}</TableCell>
                  <TableCell>{t("active")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
                {(!props?.customersData?.[owner.id_owner] || props.customersData[owner.id_owner].length === 0) ?
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan="11">
                        {t("no_customers_found")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                : <TableBody>
                  {props.customersData[owner.id_owner].map((item, index) => (
                  <TableRow key={owner_index + "_" + index} onClick={() => onClickCustomer(item.id, item.id_owner)} onKeyDown={() => onClickCustomer(item.id, item.id_owner)} hover={true} sx={{cursor: "pointer"}}>
                    <TableCell>{item.relation_name ? item.relation_name : t("customer_without_name")}</TableCell>
                    <TableCell align="right">{item.relation_address}</TableCell>
                    <TableCell align="right">{item.relation_zipcode}</TableCell>
                    <TableCell>{item.relation_place}</TableCell>
                    <TableCell>{item.total_one_off_costs_excl_vat !== undefined?(Number)(item.total_one_off_costs_excl_vat).toFixed(2):""}</TableCell>
                    <TableCell>{item.total_recurring_costs_excl_vat !== undefined?(Number)(item.total_recurring_costs_excl_vat).toFixed(2):""}</TableCell>
                    <TableCell>{Moment(item.creation).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>
                      <Slider
                        onClick={doNothing}
                        onKeyDown={doNothing}
                        onChange={handleChanceOfSuccessChange(item.id_owner, String(index))}
                        onBlur={handleChanceOfSuccessSave(item.id_owner, String(index))}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={100}
                        value={item.chance_of_success}
                        disabled={item.id_owner !== token.payload.sub}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        onClick={doNothing}
                        onChange={handleActiveChange(item.id_owner, String(index))}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={item.active === true ? true : false}
                        disabled={item.id_owner !== token.payload.sub}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ width: 0, textAlign: 'right' }}
                      >
                      <Button
                        type="submit"
                        variant="contained"
                        color='secondary'
                        onClick={(e) => onClickOverviewButton(e, item.id, item.id_owner)}
                        sx={{ borderRadius: '9px', whiteSpace: 'nowrap'  }}
                      >
                        {t('overview')}
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{ width: 0, textAlign: 'right' }}
                      >
                      <Button
                        type="submit"
                        variant="contained"
                        color='secondary'
                        onClick={(e) => onClickDeleteButton(e, item.relation_name ? item.relation_name : t("customer_without_name"), item.id, item.id_owner)}
                        sx={{ borderRadius: '9px', whiteSpace: 'nowrap'  }}
                      >
                        {t('delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
            </Fragment>
          ))}
        </Table>
      </Box>
      <Box sx={{ height: '37px', mt: 1, mb: 1, textAlign: 'center', position: 'relative' }}>
        <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => onClickReturnToDashboard()}
          sx={{ borderRadius: '9px', position: 'absolute', left: 0}}
        >
          {t('return_to_dashboard')}
        </Button>
      </Box>
    </ThemeProvider>
  )
}