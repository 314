import React from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useParams } from "react-router-dom"
import { PDFViewer, Page, Text, View, Document, Image } from '@react-pdf/renderer'
import { getCustomer, setCustomer, getHardware, setHardware,getSuppliers, setSuppliers, getUserSettings, setUserSettings } from "../redux/dataslices/inventarisationSlice"
import { GetCustomer, GetAllHardwareList, GetSuppliersList, GetUserSettings } from "../helpers/dataHandlers/Inventarisation"
import { t, changeLanguage } from 'i18next'
import { styles } from "./orderListStyleSheet"
import companyInfo from '../quoteConfig/companyInfo.json'
import ActablueLogofull from '../static/icons/logo_png_transparant.png'

function OrderList(props) {
  const {customerId} = useParams()
  const {ownerId} = useParams()
  const dispatch = useDispatch()
  const token = useSelector(getToken)
  const customer = useSelector(getCustomer)
  const suppliers = useSelector(getSuppliers)
  const hardware = useSelector(getHardware)
  const userSettings = useSelector(getUserSettings)
  const [customerData, setCustomerData] = useState()
  const [hardwareData, setHardwareData] = useState()
  const [suppliersData, setSuppliersData] = useState()

  const loadUserSettings = async () => {
    GetUserSettings(token)
      .then(response => { dispatch(setUserSettings(response.data)) })
      .catch(error => { })
  }

  useEffect(() => {
    const fetchCustomer = async () => {
      GetCustomer(token, customerId, ownerId)
        .then(response => { dispatch(setCustomer(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchHardware = async () => {
      GetAllHardwareList(token, customerId, ownerId)
        .then(response => { dispatch(setHardware(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchSuppliers = async () => {
      GetSuppliersList(token, customerId, ownerId)
        .then(response => { dispatch(setSuppliers(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    fetchCustomer()
    fetchHardware()
    fetchSuppliers()
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (!customer) return
    setCustomerData(customer)
    // eslint-disable-next-line
  }, [customer])
  
  useEffect(() => {
    if (!hardware) return
    let hardwareList = {}
    for (const value of Object.values(hardware)) {
      if(!hardwareList.hasOwnProperty(value.id_supplier)) {
        hardwareList[value.id_supplier] = []
        hardwareList[value.id_supplier]["total"] = 0
      }
      hardwareList[value.id_supplier]["total"] += value.hardware_quantity * ((Number)(value.supplier_price).toFixed(2))
      hardwareList[value.id_supplier].push(value)
    }
    setHardwareData(hardwareList)
    // eslint-disable-next-line
  }, [hardware])

  useEffect(() => {
    if (!suppliers) return
    setSuppliersData(suppliers)
    // eslint-disable-next-line
  }, [suppliers])

  useEffect(() => {
    if(userSettings?.user_language) {
      changeLanguage(userSettings.user_language)
    }
  }, [userSettings])

  const RenderOrderList = () => (
    <Document>
      {hardwareData ? Object.entries(hardwareData).map(([id_supplier, products]) => (
        <SupplierPage id_supplier={id_supplier} products={products}/>
      )):<></>}
      {hardwareData && hardwareData.length < 1 ? 
        <SupplierPage id_supplier="" products={[{hardware_description:t("no_articles_ordered"), hardware_quantity: 0, supplier_price: 0}]}/>
      :<></>}
    </Document>
  )

  const PageHeader = () => (  
    <View fixed style={styles.header_logo}>
      <Image src={ActablueLogofull} />
    </View>
  )

  const PageFooter = () => (
    <View fixed style={styles.footer}>
      <Text style={styles.footer_left}>
      {t("order_list_for", {name: customerData?.relation_name})}
      </Text>
      <Text style={styles.footer_right}>
        {companyInfo.website} / {companyInfo.telephone}
      </Text>
    </View>
  )
  
  const SupplierPage = ({id_supplier, products}) => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <Text style={styles.supplier_name}>{suppliersData?.[id_supplier]?.supplier_name ? suppliersData[id_supplier].supplier_name : t("unknown_supplier")}</Text>
        <Text style={styles.supplier_data_margin}>{suppliersData?.[id_supplier]?.supplier_website}</Text>
        <Text style={styles.supplier_data}>{suppliersData?.[id_supplier]?.supplier_street}{suppliersData?.[id_supplier]?.supplier_street?<> </>:<></>}{suppliersData?.[id_supplier]?.supplier_street_number}{suppliersData?.[id_supplier]?.supplier_street_number_additional}</Text>
        <Text style={styles.supplier_data}>{suppliersData?.[id_supplier]?.supplier_zip_code}{suppliersData?.[id_supplier]?.supplier_zip_code?<> </>:<></>}{suppliersData?.[id_supplier]?.supplier_place}</Text>
        <Text style={styles.supplier_data_margin}>{suppliersData?.[id_supplier]?.supplier_country}</Text>
        <Text style={styles.supplier_data}>{suppliersData?.[id_supplier]?.supplier_email}</Text>
        <Text style={styles.supplier_data}>{suppliersData?.[id_supplier]?.supplier_phone_number}</Text>
          <View style={styles.header}>
            <Text style={styles.header_45_L}>{t("quantity")}</Text>
            <Text style={styles.header_75_L}>{t("order_number")}</Text>
            <Text style={styles.header_60_L}>{t("brand")}</Text>
            <Text style={styles.header_220_L}>{t("description")}</Text>
            <Text style={styles.header_50_R}>{t("price")}</Text>
            <Text style={styles.header_50_R}>{t("amount")}</Text>
          </View>
          {products ? products.map((line, index) => (index === "total"?null:
            <View 
              key={index}
              style={styles.line}
              >
              <Text style={styles.line_45_L}>{line.hardware_quantity}</Text>
              <Text style={styles.line_75_L}>{line.supplier_article_code}</Text>
              <Text style={styles.line_60_L}>{line.hardware_brand}</Text>
              <Text style={styles.line_220_L}>{line.hardware_description}</Text>
              <Text style={styles.line_50_R}>{(Number)(line.supplier_price).toFixed(2)}</Text>
              <Text style={styles.line_50_R}>{(Number)(line.hardware_quantity * line.supplier_price).toFixed(2)}</Text>
            </View>
          )):null}
          <View style={styles.header}>
            <Text style={styles.header_45_L}></Text>
            <Text style={styles.header_75_L}></Text>
            <Text style={styles.header_60_L}></Text>
            <Text style={styles.header_220_L}>{t("total")}</Text>
            <Text style={styles.header_105_R}>{products['total']?(Number)(products['total']).toFixed(2):(Number)(0).toFixed(2)}</Text>
          </View>
      </View>
      <PageFooter/>
    </Page>
  )

  if (!userSettings) {
    loadUserSettings()
  }

  return (
    <>
      <style>{`
        html {
          overflow: hidden
        }
      `}</style>
      <PDFViewer style={{width:"100%", height:"100%", border: 0, margin: 0, padding: 0}}>
        <RenderOrderList />
      </PDFViewer>
    </>
  )
}

export default OrderList