import Card from "../components/card"
import UserSettingsPanel from "../components/userSettingsPanel"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getUserSettings, setUserSettings } from "../redux/dataslices/inventarisationSlice"
import { GetUserSettings, UpdateUserSettings } from "../helpers/dataHandlers/Inventarisation"
import { useNavigate } from 'react-router-dom'

function UserSettings() {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const userSettings = useSelector(getUserSettings)
  const [userSettingsData, setUserSettingsData] = useState()
  const navigate = useNavigate()

  
  const updateDataField = (field, value) => {
    dispatch(setUserSettings({...userSettings, [field]:value}))
    setUserSettingsData({...userSettingsData, [field]:value})
  }

  const updateUserSettings = (dataObject, redirect) => {
    UpdateUserSettings(token, dataObject)
    .then(response => { updateCompleted(response.data, redirect)})
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const updateCompleted = (dataObject, redirect) => {
    dispatch(setUserSettings(dataObject))
    if(redirect != null) {
      navigate(redirect)
    }
  }

  const userSettingsCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'settings',
    loading: loading,
    loadingText: "loading_settings",
    data: <UserSettingsPanel  
      id_owner={token.payload.sub}
      userSettingsData={userSettingsData}
      updateDataField={updateDataField}
      updateUserSettings={updateUserSettings}
      />
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      GetUserSettings(token)
        .then(response => { dispatch(setUserSettings(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line
  }, [token])
    
  useEffect(() => {
    if (!userSettings) return
    setLoading(true)
    setUserSettingsData(userSettings)

    // eslint-disable-next-line
  }, [userSettings])

  useEffect(() => {
    if (userSettingsData && userSettingsData !== null) {
      setLoading(false)
    }
  }, [userSettingsData])

  return (
      <>
        <Card {...userSettingsCardProps} />
      </>
  )
}

export default UserSettings
