import { configureStore } from '@reduxjs/toolkit'
import drawerReducer from "./dataslices/drawerSlice"
import tokenReducer from "./dataslices/tokenSlice"
import inventarisationReducer from "./dataslices/inventarisationSlice"

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    drawer: drawerReducer,
    inventarisation: inventarisationReducer
  },
})