import Card from "../components/card"
import ProductTable from "../components/productTable"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getProducts, setProducts } from "../redux/dataslices/inventarisationSlice"
import { GetHardware, UpdateHardware, GetProductsList, GetProduct } from "../helpers/dataHandlers/Inventarisation"
import { useNavigate, useParams } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid'

function Products() {
  const {customerId} = useParams()
  const {pageNumber} = useParams()
  const {hardwareType} = useParams()
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const products = useSelector(getProducts)
  const [productsData, setProductsData] = useState()
  const navigate = useNavigate()
  var categoryId = null
  
  try {
    var pageData = require('../pages/'.concat(pageNumber,'.json'))    
    pageData.fields.every(field => {if(field.database === "hardware"){categoryId = process.env.REACT_APP_PRODUCT_API_USE_STAGING_CATEGORIES === "true" ? field.product_category_staging : field.product_category; return false} else {return true}})
  }
  catch(e) {
  }

  const selectHardware = (product_guid) => {  
    setLoading(true)
    GetProduct(token, product_guid)
      .then(response => { checkHardware(response.data) })
      .catch(error => {
        enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
        setLoading(false)
      })
  }

  const checkHardware = (product_data) => {  
    setLoading(true)
    GetHardware(token, customerId, product_data.id)
      .then(response => { incrementHardware(response.data) })
      .catch(error => { createHardware(product_data) })
  }

  const createHardware = (product_data) => {  
    const guid = uuidv4()
    var dataObject = { id: guid, id_owner: token.payload.sub, id_product: product_data.id, id_customer: customerId, hardware_type: hardwareType, hardware_brand: product_data.product_brand, hardware_description: product_data.product_name, hardware_sales_price:(Number)(product_data.product_price_excl_vat).toFixed(2), id_supplier: product_data.id_supplier, supplier_article_code: product_data.supplier_article_code, supplier_price:(Number)(product_data.supplier_price).toFixed(2), hardware_quantity: 1 } 
    UpdateHardware(token, dataObject)
      .then(returnToCustomer())
      .catch(error => {
        enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
        setLoading(false)
      })
  }

  const incrementHardware = (product_data) => {
    product_data.hardware_quantity = product_data.hardware_quantity + 1
    UpdateHardware(token, product_data)
      .then(returnToCustomer())
      .catch(error => {
        enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
        setLoading(false)
      })
  }

  const returnToCustomer = () => {  
    navigate("/customer/".concat(customerId,"/",pageNumber,"/"))
  }

  const productListCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'list_of_products',
    loading: loading,
    loadingText: "loading_products",
    data: <ProductTable data={productsData} selectHardware={selectHardware} returnToCustomer={returnToCustomer}/>
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      GetProductsList(token, categoryId)
        .then(response => { dispatch(setProducts(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line
  }, [token])
  
  useEffect(() => {
    if (!products) return
    setLoading(true)
    let productsList = []
    for (const value of Object.values(products)) {
      productsList.push(value)
    }
    productsList.sort((a, b) => a.sku_code > b.sku_code ? 1 : -1)
    setProductsData(productsList)

    // eslint-disable-next-line
  }, [products])

  useEffect(() => {
    if (productsData && productsData.length > 0) {
      setLoading(false)
    }
  }, [productsData])

  return (
      <>
        <Card {...productListCardProps} />
      </>
  )
}

export default Products
