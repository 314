import { createSlice } from '@reduxjs/toolkit'
import { deepCopy } from '../../helpers/objectHelpers'
import { decodeToken } from 'react-jwt'

const initialState = {
  token: JSON.parse(localStorage.getItem('token')),
  role: JSON.parse(localStorage.getItem('role'))
}

export function GetPayloadFromToken(token) {
  if (token) {
    const payload = decodeToken(token.access_token)
    let role = 'user'
    if (payload.realm_access?.roles?.includes('merchant')) role = 'merchant'
    if (payload.realm_access?.roles?.includes('reseller')) role = 'reseller'
    if (payload.realm_access?.roles?.includes('admin')) role = 'admin'
    if (payload.realm_access?.roles?.includes('super')) role = 'super'

    return [payload, role]
  }
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      if (action.payload) {
        const token = deepCopy(action.payload)
        const [payload, role] = GetPayloadFromToken(action.payload) || []
        token.payload = payload
        token.role = role
        state.token = token
        state.role = role
        localStorage.setItem('token', JSON.stringify(token))
        localStorage.setItem('role', JSON.stringify(role))
      }
    },
    clearToken: (state) => {
      state.token = undefined
      state.role = undefined
      localStorage.removeItem('token')
      localStorage.removeItem('role')
    }
  },
})

export const { setToken, clearToken } = tokenSlice.actions

export const getToken = (state) => state.token.token
export const getRole = (state) => state.token.role

export default tokenSlice.reducer