import { StyleSheet } from '@react-pdf/renderer'
import { Font } from '@react-pdf/renderer'
import OpenSans from '../static/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../static/fonts/OpenSans-Bold.ttf'

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSans
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold'
    }
  ]
})

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '20px',
    paddingBottom: '60px'
  },
  header_logo: {
    width: '250px',
    height: '120px',
    margin: '30px 0 0 300px'
  },
  footer: {
    fontSize: '12pt',
    fontFamily: 'OpenSans',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    right: '20px'
  },
  footer_left: {
    width: '50%'
  },
  footer_right: {
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  title_page: {
    textAlign: 'center',
    width: '100%',
    fontSize: '18pt',
    fontFamily: 'OpenSans',
    marginTop: '130px'
  },
  title_page_small: {
    fontSize: '12pt'
  },
  title_page_small_bold: {
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  index_table: {
    fontFamily: 'OpenSans',
    marginLeft: '40px'
  },
  index_header: {
    fontSize: '18pt',
    textDecoration: 'underline'
  },
  index_category: {
    fontSize: '12pt',
    fontWeight: 'bold',
    paddingLeft: '50px',
    display: 'inline-block',
    textDecoration: 'none',
    color: '#000'
  },
  index_item: {
    flexDirection: 'row',
    textDecoration: 'none',
    color: '#000'
  },
  index_item_number: {
    fontSize: '12pt',
    width: '60px'
  },
  index_item_description: {
    fontSize: '12pt'
  },
  page_table: {
    fontFamily: 'OpenSans',
    marginLeft: '25px'
  },
  page_title: {
    fontSize: '18pt',
    flexDirection: 'row'
  },
  page_subtitle: {
    fontSize: '10pt',
    marginLeft: '35px'
  },
  category: {
    fontSize: '10pt',
    flexDirection: 'row',
    marginTop: '10px'
  },
  category_number: {
    width: '35px'
  },
  category_description: {
    fontWeight: 'bold'
  },
  category_description_short: {
    fontWeight: 'bold',
    width: '110px',
  },
  header: {
    fontSize: '10pt',
    flexDirection: 'row',
    paddingLeft: '30px'
  },
  header_45_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '45px'
  },
  header_60_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '60px'
  },
  header_270_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '270px'
  },
  header_50_R: {
    fontWeight: 'bold',
    marginLeft: '5px',
    textAlign: 'right',
    width: '50px'
  },
  header_310_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '310px'
  },
  line: {
    fontSize: '10pt',
    flexDirection: 'row',
    paddingLeft: '30px'
  },
  nonPaddedLine: {
    fontSize: '10pt',
    flexDirection: 'row'
  },
  line_L: {
    marginLeft: '5px'
  },
  line_10_L: {
    marginLeft: '5px',
    width: '10px'
  },
  line_45_L: {
    marginLeft: '5px',
    width: '45px'
  },
  line_60_L: {
    marginLeft: '5px',
    width: '60px'
  },
  line_270_L: {
    marginLeft: '5px',
    width: '270px'
  },
  line_310_L: {
    marginLeft: '5px',
    width: '310px',
  },
  line_50_R: {
    marginLeft: '5px',
    textAlign: 'right',
    width: '50px'
  },
  totals: {
    marginTop: '5px',
    flexDirection: 'row'
  },
  confirmation_page_category: {
    flexDirection: 'row',
    marginTop: '10px'
  },
  confirmation_page_category_number: {
    fontSize: '10pt',
    width: '35px',
    position: 'absolute',
    bottom: '3px'
  },
  confirmation_page_category_description: {
    marginLeft: '35px',
    fontSize: '18pt',
    fontWeight: 'bold'
  },
  confirmation_page_line: {
    marginTop: '15px',
    marginLeft: '35px',
    fontSize: '18pt',
    overflow: 'hidden',
    position: 'relative'
  },
  confirmation_page_text: {
    backgroundColor: '#fff'
  },
  confirmation_page_decoration: {
    position: 'absolute',
    left: 0,
    top: '4px'
  }
})