import { createTheme } from "@mui/material"

const mainTheme = createTheme({
    palette: {
      primary: {
        main: '#00072d',
        contrastText: '#fff',
      },
      secondary: {
        main: '#FF6700',
        contrastText: '#fff',
      },
      logoBlue: {
        main: '#005995',
        dark: '#003e68',
        contrastText: '#fff',
      },
      text: {
        primary: '#000000'
      }
    },
    typography: {
      fontFamily: [
        'Montserrat',
      ].join(','),
    },
  })

export default mainTheme