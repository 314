import { Box, Button, CircularProgress, Container, CssBaseline, TextField, ThemeProvider } from '@mui/material'
import ActablueLogofull from '../static/icons/logo_png_transparant.png'
import mainTheme from '../css/themes/mainTheme'
import { useEffect, useState } from 'react'
import { LoginKeyCloakUser } from '../helpers/dataHandlers/KeycloakAuth'
import { getToken, setToken } from '../redux/dataslices/tokenSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CopyrightText from '../components/copyrightText'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(getToken)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    if (token && token.access_token) { // TODO: Add token expired check and check token from session/local storage
      navigate("/dashboard")
      return
    }
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')

    setLoading(true)

    if (typeof email === 'string' && typeof password === 'string')
      LoginKeyCloakUser(email, password)
        .then(response => {
          dispatch(setToken(response))
          localStorage.setItem("previous_logged_in_email", email)
          setLoading(false)
          navigate("/dashboard")
        })
        .catch(error => {
          enqueueSnackbar({
            message: `${error?.response?.data?.error_description ?? error?.message}`, variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          setLoading(false)
        })
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Ensure this Box takes up the full height of its parent
        }}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              marginBottom={2}
              sx={{
                width: '100%',
                maxWidth: 350,
                textAlign: 'center'
              }}
            >
              <img
                src={ActablueLogofull}
                alt='ActaBlue Logo'
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("email_address")}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color='secondary'
                sx={{ mt: 3, mb: 2, borderRadius: '9px', height: 48 }}
              >
                {loading ? (
                  <CircularProgress color='inherit' size={32} />
                ) : (
                  t('login')
                )}
              </Button>
            </Box>
          </Box>
          <CopyrightText />
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Login
