import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { Box, CssBaseline, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function ErrorMessage(props) {
  const { t } = useTranslation()

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box>
        <Typography variant="h6" fontWeight={400} 
          sx={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', marginTop: 2}}>
            {t(props?.data)}
          </Typography>          
      </Box>
    </ThemeProvider>
  )
}