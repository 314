import { StyleSheet } from '@react-pdf/renderer'
import { Font } from '@react-pdf/renderer'
import OpenSans from '../static/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../static/fonts/OpenSans-Bold.ttf'

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSans
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold'
    }
  ]
})

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '20px',
    paddingBottom: '60px'
  },
  header_logo: {
    width: '250px',
    height: '120px',
    margin: '30px 0 0 300px'
  },
  footer: {
    fontSize: '12pt',
    fontFamily: 'OpenSans',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    right: '20px'
  },
  footer_left: {
    width: '50%'
  },
  footer_right: {
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  page_table: {
    fontFamily: 'OpenSans',
    marginLeft: '25px'
  },
  supplier_name: {
    fontSize: '18pt',
    flexDirection: 'row'
  },
  supplier_data: {
    fontSize: '12pt',
    flexDirection: 'row'
  },
  supplier_data_margin: {
    fontSize: '12pt',
    flexDirection: 'row',
    marginBottom: '10px'
  },
  header: {
    fontSize: '10pt',
    flexDirection: 'row',
    marginTop: '10px'
  },
  header_45_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '45px'
  },
  header_60_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '60px'
  },
  header_75_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '75px'
  },
  header_220_L: {
    fontWeight: 'bold',
    marginLeft: '5px',
    width: '220px'
  },
  header_50_R: {
    fontWeight: 'bold',
    marginLeft: '5px',
    textAlign: 'right',
    width: '50px'
  },
  header_105_R: {
    fontWeight: 'bold',
    marginLeft: '5px',
    textAlign: 'right',
    width: '105px'
  },
  line: {
    fontSize: '10pt',
    flexDirection: 'row'
  },
  line_45_L: {
    marginLeft: '5px',
    width: '45px'
  },
  line_60_L: {
    marginLeft: '5px',
    width: '60px'
  },
  line_75_L: {
    marginLeft: '5px',
    width: '75px'
  },
  line_220_L: {
    marginLeft: '5px',
    width: '220px'
  },
  line_50_R: {
    marginLeft: '5px',
    textAlign: 'right',
    width: '50px'
  }
})