import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { useEffect, useState, useCallback, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Box, CssBaseline, CircularProgress, TextField, MenuItem, FormControlLabel, Switch, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSelector } from "react-redux"
import { ConditionsMet } from "../helpers/dataHandlers/Inventarisation"
import { useTranslation } from 'react-i18next'
import { useSnackbar } from "notistack"
import Slider from '@mui/material/Slider'
import '../css/formFields.css'
import { store } from '../redux/store'
import { TranslateCustom as tc } from '../helpers/translation'

export default function FieldParser(props) {
  const { t } = useTranslation()
  const token = useSelector(getToken)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [previousPage, setPreviousPage] = useState(false)
  const navigate = useNavigate()

  const fieldBlocks = [[]]
  var i = 0;
  props?.pageConfig?.fields.forEach((field, index) => {
    if((field.type === "label" || field.type === "checkbox" || field.type === "table") && index > 0) {
      i++
      fieldBlocks[i] = []
    }
    if(field.type === "checkbox") {
      fieldBlocks[i].push({"type": "label", "description": field.description})
      i++
      fieldBlocks[i] = []
    }
    fieldBlocks[i].push(field)
    if((field.type === "label" || field.type === "checkbox" || field.type === "table") && index + 1 < props?.pageConfig?.fields.length) {
      i++
      fieldBlocks[i] = []
    }
  });

  const submitCustomerData = useCallback((customerObject, callback = null) => {
    if(props?.customerData?.highest_page_reached < props?.pageNumber) {
      customerObject.highest_page_reached = props?.pageNumber
    }
    if(props?.dirtyState?.['customer']?.[0] === true || props?.customerData?.highest_page_reached < props?.pageNumber) {
      props?.updateCustomer(customerObject, callback)
    }
    else {
      props?.requestCompleted(callback)
    }
    setLoading(false)
  },[props])

  const checkField = useCallback((dataSet, recid, outputObject, fieldConfig, callback) => {
    if(dataSet[recid] && fieldConfig.type !== 'description') {
      if(fieldConfig.unique_value) {
        for(const value of dataSet) {
          if((String)(value[fieldConfig.name]) === (String)(dataSet[recid][fieldConfig.name]) && value.id !== dataSet[recid].id) {
            enqueueSnackbar({ message: t("field_not_unique", {name: tc(fieldConfig.description)}), variant: 'error' })
            setLoading(false)
            return
          }
        }
      }
      var childObject
      switch(fieldConfig.type) {
        case 'table':
          var tableDataSet
          switch(fieldConfig.database) {
            case "hardware":
              tableDataSet = props.hardwareData
              break
            case "employees":
              tableDataSet = props.employeesData
              break
            case "cashdrawers":
              tableDataSet = props.cashDrawersData
              break
            case "quoteconditions":
              tableDataSet = props.quoteConditionsData
              break
            default:
              break
          }
          if(tableDataSet) {
            for (let i=0; i<tableDataSet.length; i++) {
              if(props?.dirtyState?.[fieldConfig.database]?.[i] === true) {
                childObject = { id: tableDataSet[i].id, id_owner: props?.customerData?.id_owner, id_customer: props?.customerData?.id }  
                for (const tableField of fieldConfig.fields) {
                  childObject = checkField(tableDataSet, i, childObject, tableField, childObject, callback)
                  if(!childObject) {
                    return
                  }
                }
                switch(fieldConfig.database) {
                  case "hardware":  
                    childObject.id_product = tableDataSet[i].id_product
                    childObject.hardware_type = tableDataSet[i].hardware_type
                    props?.updateHardware(childObject, callback)
                    break
                  case "employees":
                    props?.updateEmployee(childObject, callback)
                    break
                  case "cashdrawers":
                    props?.updateCashDrawer(childObject, callback)
                    break
                  case "quoteconditions":
                    props?.updateQuoteCondition(childObject, callback)
                    break
                  default:
                    break
                }
              }
            }
          }
          break
        case 'list': 
          for (const [database, settings] of Object.entries(props.listSettingsData)) {
            for (const [identifier, value] of Object.entries(settings)) {
              if(props?.dirtyState?.["list_"+database]?.[identifier] === true) {
                props?.updateListSetting(value, callback)
              }
            }
          }
          break
        default:
          outputObject[fieldConfig.name] = dataSet[recid][fieldConfig.name]
      }
    }
    return outputObject
  },[props, enqueueSnackbar, t])

  const savePageData = useCallback((pageConfig = props?.pageConfig, callback = null) => {
    if(ConditionsMet(props?.customerData, props?.listSettingsData, pageConfig?.conditions, pageConfig?.conditions_required)) {
      setLoading(true)
      var customerObject = { id: props?.customerData?.id, id_owner: props?.customerData?.id_owner }  
      for (const field of pageConfig.fields) {
        if(ConditionsMet(props?.customerData, props?.listSettingsData, field?.conditions, field?.conditions_required)) {        
          customerObject = checkField([props?.customerData], 0, customerObject, field, callback)
          if(!customerObject) {
            return
          }
        }
      }
      submitCustomerData(customerObject, callback)
    }
  },[props, checkField, submitCustomerData])

  const getNextPage = (currentPage, direction) => {
    var nextPageData
    try {
      nextPageData = require('../pages/'.concat(currentPage + direction,'.json'))
      if(ConditionsMet(props?.customerData, props?.listSettingsData, nextPageData?.conditions, nextPageData?.conditions_required)) {
        return currentPage + direction
      }
      else {
        return getNextPage(currentPage + direction, direction)
      }
    }
    catch(e) {
      return 0
    }
  }

  const goToNextPage = () => {
    let base_url = "/customer/".concat(props?.customerData?.id,"/",getNextPage((Number)(props?.pageNumber), 1))
    if(props?.customerData?.id_owner === token.payload.sub) {
      navigate(base_url)
    }
    else {
      navigate(base_url.concat("/", props?.customerData?.id_owner))
    }
  }

  const goToPreviousPage = () => {
    let base_url = "/customer/".concat(props?.customerData?.id,"/",getNextPage((Number)(props?.pageNumber), -1))
    if(props?.customerData?.id_owner === token.payload.sub) {
      navigate(base_url)
    }
    else {
      navigate(base_url.concat("/", props?.customerData?.id_owner))
    }
  }

  const handleNextPage = (event) => {
    setPreviousPage(null)
    savePageData(props?.pageConfig, goToNextPage)
  }

  const handlePreviousPage = (event) => {
    setPreviousPage(null)
    savePageData(props?.pageConfig, goToPreviousPage)
  }

  const buttonAction = (action) => {
    switch(action) {
      case "coc_request":
        handleCocRequest()
        break
      case "open_quote_pdf":
        handleOpenQuotePdf()
        break
      case "open_orderlist_pdf":
        handleOpenOrderListPdf()
        break
      default:
        break
    }
  }

  const handleCocRequest = () => {
    savePageData(props?.pageConfig, props?.getDataFromCoc)
  }

  const handleOpenQuotePdf = () => {
    let base_url = '/quote/' + props?.customerData?.id
    if(props?.customerData?.id_owner === token.payload.sub) {
      window.open(base_url, '_blank')?.focus()
    }
    else {
      window.open(base_url.concat("/", props?.customerData?.id_owner), '_blank')?.focus()
    }
  }

  const handleOpenOrderListPdf = () => {
    let base_url = '/orderlist/' + props?.customerData?.id
    if(props?.customerData?.id_owner === token.payload.sub) {
      window.open(base_url, '_blank')?.focus()
    }
    else {
      window.open(base_url.concat("/", props?.customerData?.id_owner), '_blank')?.focus()
    }
  }
  
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    })
  }

  const handleCreateRecord = (event) => {
    if(ConditionsMet(props?.customerData, props?.listSettingsData, props?.pageConfig?.conditions, props?.pageConfig?.conditions_required)) {
      event.preventDefault()
      setLoading(true)  
      switch(event.currentTarget.value) {
        case "hardware":
          props?.navigateToHardwareSelection(event.currentTarget.name)
          break
        case "employees":
          props?.createEmployee(scrollToBottom)
          break
        case "cashdrawers":
          props?.createCashDrawer(scrollToBottom)
          break
        case "quoteconditions":
          props?.createQuoteCondition(scrollToBottom)
          break
        default:
          break
      }
      var customerObject = { id: props?.customerData?.id, id_owner: props?.customerData?.id_owner }  
      for (const field of props?.pageConfig?.fields) {
        customerObject = checkField([props?.customerData], 0, customerObject, field, event.currentTarget.value, event.currentTarget.value !== "hardware" ? scrollToBottom : null)
        if(!customerObject) {
          return
        }
      }
      submitCustomerData(customerObject, event.currentTarget.value !== "hardware" ? scrollToBottom : null)
    }
  }

  const handleDeleteRecord = (event) => {
    if(ConditionsMet(props?.customerData, props?.listSettingsData, props?.pageConfig?.conditions, props?.pageConfig?.conditions_required)) {
      event.preventDefault()
      setLoading(true)
      switch(event.currentTarget.value) {
        case "hardware":
          props?.deleteHardware(event.currentTarget.name)
          break
        case "employees":
          props?.deleteEmployee(event.currentTarget.name)
          break
        case "cashdrawers":
          props?.deleteCashDrawer(event.currentTarget.name)
          break
        case "quoteconditions":
          props?.deleteQuoteCondition(event.currentTarget.name)
          break
        default:
          break
      }
      var customerObject = { id: props?.customerData?.id, id_owner: props?.customerData?.id_owner }  
      for (const field of props?.pageConfig?.fields) {
        customerObject = checkField([props?.customerData], 0, customerObject, field, event.currentTarget.value)
        if(!customerObject) {
          return
        }
      }
      submitCustomerData(customerObject)
    }
  }

  const handleTextFieldEdit = (event) => {
    props.updateDataField(event.currentTarget.name, event.currentTarget.value)
  }

  const handleNumberFieldEdit = (event) => {
    var value = (Number)(event.currentTarget.value)
    if(event.currentTarget.min && value < event.currentTarget.min) {
      value = event.currentTarget.min
    }
    if(event.currentTarget.max && value > event.currentTarget.max) {
      value = event.currentTarget.max
    }
    event.currentTarget.value = value
    props.updateDataField(event.currentTarget.name, value)
  }

  const handleSelectFieldEdit = (event) => {
    props.updateDataField(event.target.name, event.target.value)
  }

  const handleNumberFieldKeyDown = (event) => {
    if (event.key === "e" || event.key === "E" || event.key === "-" || event.key === "+") {
      event.preventDefault()
    }
  }

  const handleSliderFieldEdit = (event) => {
    props.updateDataField(event.target.name, event.target.value)
  }

  const handleSwitchFieldEdit = (event) => {
    props.updateDataField(event.currentTarget.name, event.currentTarget.checked)
  }

  const handleOrderNrFieldEdit = (self, swap_target) => {
    props.swapDataFields(self, swap_target)
  }

  const handleCheckboxFieldEdit = (event) => {
    var field_name = event.target.name.split("_").slice(2).join("_")
    var data = JSON.parse((props.customerData[field_name] && props.customerData[field_name] !== null) ? props.customerData[field_name] : "[]")
    if(event.target.checked) {
      data.push(event.target.value)
    }
    else {
      data.splice(data.indexOf(event.target.value), 1)
    }
    props.updateDataField(event.target.name, JSON.stringify(data))
  }

  const onPageUnload = useCallback(() => {
    savePageData()
  },[savePageData])

  useEffect(() => {
    window.addEventListener("beforeunload", onPageUnload)
    return () => {
      window.removeEventListener("beforeunload", onPageUnload)
    }
  }, [onPageUnload])

  useEffect(() => {
    if(previousPage) {
      var previousPageData
      try {
        previousPageData = require('../pages/'.concat(previousPage,'.json'))
        savePageData(previousPageData)
      }
      catch(e) {
        return true
      }
    }
    setPreviousPage(props.pageNumber)
    // eslint-disable-next-line
  },[props.pageNumber])

  const hasValue = (variable) => {
    return variable !== undefined && variable !== null
  }

  const renderField = (table, recid, field, index, tableField) => {
    if(ConditionsMet(props?.customerData, props?.listSettingsData, field?.conditions, field?.conditions_required)) {
      var fieldId = table + "_" + recid + "_" + (field.identifier ? field.identifier + "_" : "") + field.name
      var fieldIdNext = table + "_" + (recid + 1) + "_" + field.name
      var fieldIdPrevious = table + "_" + (recid - 1) + "_" + field.name
      var fieldDescription = (field.prefix !== undefined ? field.prefix : "") + tc(field.description) + (field.postfix !== undefined ? field.postfix : "") 
      var style = { mt: (tableField?0:1), mb: (tableField?0:1), ml: 0.5, mr: 0.5, textAlign: hasValue(field.align) ? field.align : "left", input: {textAlign: hasValue(field.align) ? field.align : "left"}}
      var dataSet
      var not_unique = false;
      var dataSetCount = 1
      var tableData
      var listData = []
      switch(table) {
        case "hardware":
          dataSet = props?.hardwareData
          dataSetCount = props?.hardwareData.length
          break
        case "employees":
          dataSet = props?.employeesData
          dataSetCount = props?.employeesData.length
          break
        case "cashdrawers":
          dataSet = props?.cashDrawersData
          dataSetCount = props?.cashDrawersData.length
          break
        case "quoteconditions":
          dataSet = props?.quoteConditionsData
          dataSetCount = props?.quoteConditionsData.length
          break
        case "list":
          dataSet = props?.listSettingsData
          dataSetCount = props?.listSettingsData?.length
          break
        default:
          dataSet = [props?.customerData]
          break
      }
      var fieldValue = ""
      if(field.type === "checkbox") {
        fieldValue = "[]"
      }
      if(field.type === "list") {
        switch(field.database) {
          case "subscriptions":
            if(props?.subscriptionsData) {
              for(const subscription of props?.subscriptionsData) {
                listData.push({"name": subscription[1].subscription_name, "identifier": subscription[1].id})
              }
            }
            break
          default:
            break
        }
      }
      if(table === "list" && hasValue(dataSet?.[recid]?.[field.identifier]?.[field.name])) {
        fieldValue = dataSet[recid][field.identifier][field.name]
      } 
      else if(table !== "list" && hasValue(dataSet?.[recid]?.[field.name])) {
        fieldValue = dataSet[recid][field.name]
      } 
      else if(hasValue(field.default)) { 
        if(hasValue(field.translate_default) && field.translate_default === true) {
          fieldValue = tc(field.default)
        }
        else {
          fieldValue = field.default
        }
        props?.updateDataField(fieldId, fieldValue)
      }
      else if(hasValue(field.values?.min)) { 
        fieldValue = field.values.min
        props?.updateDataField(fieldId, fieldValue)
      }
      switch(field.database) {
        case "hardware":       
          tableData = props?.hardwareData
          break
        case "employees":
          tableData = props?.employeesData
          break
        case "cashdrawers":
          tableData = props?.cashDrawersData
          break
        case "quoteconditions":
          tableData = props?.quoteConditionsData
          break
        default:
          break
      }
      
      if(field.type === "calculation") {       
        fieldValue = (String)(field.value)
        var matches = [...field.value.matchAll(/\[(.*?)\]/g)]
        for (const match of matches) {
          fieldValue = fieldValue.replace(match[0], dataSet[recid][match[1]])
        }
        if(!/^[a-zA-Z]+$/.test(fieldValue)) {
          try{
            // eslint-disable-next-line
            fieldValue = eval(fieldValue)
          }
          catch {}
        }
      }
      if(hasValue(field?.decimals)) {
        fieldValue = (Number)(fieldValue).toFixed(field.decimals)
      }
      for (const value of dataSet) {
        if((String)(dataSet?.[recid]?.[field.name]) === (String)(value?.[field.name]) && dataSet?.[recid]?.id !== value?.id) {
          not_unique = true
        }
      }
      return (field.type === "label" &&
        <Box
          className={"input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          sx={{ml: 0.5}}
          >
          {fieldDescription}
        </Box>)
      || (field.type === "calculation" &&
        <Box
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          sx={style}
          >
          {fieldValue}
        </Box>)
      || (field.type === "text" &&
        <TextField
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          id={fieldId}
          label={fieldDescription}
          name={fieldId}
          onChange={handleTextFieldEdit}
          value={fieldValue}
          sx={style}
          disabled={token.payload.sub !== props?.customerData?.id_owner}
        />)
      || ((field.type === "largetext") &&
        <TextField
          className={"input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          id={fieldId}
          label={fieldDescription}
          name={fieldId}
          multiline
          minRows={field.height}
          onChange={handleTextFieldEdit}
          value={fieldValue}
          sx={style}
          disabled={token.payload.sub !== props?.customerData?.id_owner}
        />)
      || (field.type === "number" &&
        <TextField
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          id={fieldId}
          label={fieldDescription}
          name={fieldId}
          type="number"
          inputProps={{ min: field?.values?.min, max: field?.values?.max }}
          onKeyDown={handleNumberFieldKeyDown}
          onChange={handleNumberFieldEdit}
          value={fieldValue}
          sx={style}
          disabled={token.payload.sub !== props?.customerData?.id_owner}
        />)
      || (field.type === "select" &&
        <TextField
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          id={fieldId}
          select
          label={fieldDescription}
          name={fieldId}
          onChange={handleSelectFieldEdit}
          value={fieldValue}
          sx={style}
          disabled={token.payload.sub !== props?.customerData?.id_owner}
        >
          {field?.values === "language_list" ?
            store.languages.map((language) => (
                  <MenuItem
                  value={language}
                  key={index + "_" + language}>
                    {t("language_select_" + language)}
                  </MenuItem>
                ))
          :
            field?.values.map((option, optionindex) => (
              <MenuItem
                value={option.value}
                key={index + "_" + optionindex}>
                {tc(option.description)}
              </MenuItem>
            ))
          }
        </TextField>)
      || (field.type === 'slider' &&  
        <Box
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          sx={style}
          >       
          <Slider
            id={fieldId}
            label={fieldDescription}
            name={fieldId}
            valueLabelDisplay="auto"
            step={field.values.step ? field.values.step : 1}
            marks
            min={field.values.min}
            max={field.values.max}
            onChange={handleSliderFieldEdit}
            value={fieldValue}
            disabled={token.payload.sub !== props?.customerData?.id_owner}
          />
          <label htmlFor={field.name} id={field.name + "-label"}>{fieldDescription}</label>
        </Box>)
      || (field.type === "toggle" &&
        <FormControlLabel
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          control={
            <Switch
              id={fieldId}
              name={fieldId}
              onChange={handleSwitchFieldEdit}
              inputProps={{ 'aria-label': 'controlled' }}
              checked={fieldValue === true ? true : false}
              disabled={token.payload.sub !== props?.customerData?.id_owner}
            />
          }
          label={fieldDescription}
          labelPlacement="start"
          sx={style}
          key={index}
        />)
      || (field.type === "ordernr" &&
        <Box
          className={"resizableChild input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          >
          <Button
            type="submit"
            variant="contained"
            color='secondary'
            disabled={recid < 1 || token.payload.sub !== props?.customerData?.id_owner}
            onClick={() => handleOrderNrFieldEdit(fieldId, fieldIdPrevious)}
            sx={{ mr: 2, borderRadius: '9px' }}
          >
            ↑
          </Button>
          <Button
            type="submit"
            variant="contained"
            color='secondary'
            disabled={recid + 1 === dataSetCount || token.payload.sub !== props?.customerData?.id_owner}
            onClick={() => handleOrderNrFieldEdit(fieldId, fieldIdNext)}
            sx={{ borderRadius: '9px' }}
          >
            ↓
          </Button>
        </Box>
        )
      || (field.type === "checkbox" &&
        <Box
          className={"input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index}
          sx={{mb: 1}}>
          <TextField
            key={index + "_hidden"}
            id={fieldId}
            name={fieldId}
            value={(String)(fieldValue)}
            sx={{ display: "none"}}
          ></TextField>
          <Box>
            {field?.values.map((option, optionindex) => (
              <FormControlLabel
                control={
                  <Checkbox 
                    id={field.name + "_" + optionindex}
                    name={fieldId}
                    value={option.value}
                    onChange={handleCheckboxFieldEdit}
                    checked={JSON.parse(fieldValue).includes(option.value) === true ? true : false}
                    disabled={token.payload.sub !== props?.customerData?.id_owner}
                  />
                }
                label={tc(option.description)}
                labelPlacement="start"
                className="resizableChild"
                sx={{ mt: 1, mb: 1, ml: 0.5, mr: 0.5}}
                key={index + "_" + optionindex}
                disabled={token.payload.sub !== props?.customerData?.id_owner}
              />
            ))}
          </Box>
        </Box>)
      || (field.type === "table" && table === "customer" &&
        <Table
          className={"input-" + field.type + " uniqueError-" + (not_unique && field.unique_value)}
          key={index + "_table"}
          sx={{ml: 0.5, mr: 0.5, mb: 1.5, width: "calc(100% - 8px)"}}>
          <TableHead>
            <TableRow>
              {field.fields.map((header, headerindex) => (header.type !== "largetext" ?
                <TableCell 
                  key={index + '_head_' + (headerindex + 1)}
                  sx={{ pl: 2 , textAlign: hasValue(header.align) ? header.align: "left"}}
                  >
                  {t(header.description)}
                </TableCell>
              : <></>))}
              {((hasValue(field.create) && field.create !== false) || (hasValue(field.delete) && field.delete !== false) ?
                ((hasValue(field.create) && field.create !== false) ?
                  <TableCell 
                    key={index + '_head_0'}
                    sx={{ width: 0, textAlign: 'right' }}
                    >
                    <Button
                      type="submit"
                      variant="contained"
                      color='secondary'
                      name={field.database_filter}
                      value={field.database}
                      onClick={handleCreateRecord}
                      sx={{ borderRadius: '9px', whiteSpace: 'nowrap' }}
                      disabled={token.payload.sub !== props?.customerData?.id_owner}
                    >
                      {loading ? (
                        <CircularProgress color='inherit' size={32} />
                      ) : (
                        tc(field?.create, 'create')
                      )}
                    </Button>
                  </TableCell>
                :<></>)
              :<></>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {(!tableData || tableData.length === 0 ? 
            <TableRow key={index + '_notfound'}>
              <TableCell
              key={index + '_notfound_0'}
              colSpan={((hasValue(field.create) && field.create !== false) || (hasValue(field.delete) && field.delete !== false)) + field.fields.length}
              sx={{ width: 0 }}
              >
                {tc(field.notfound,"no_records_found")}
              </TableCell>
            </TableRow>
            :<></>)}
            {(tableData)?.map((item, subindex) => (
            <>
              <TableRow key={index + '_' + subindex}>
                {field.fields.map((itemfield, fieldindex) => (itemfield.type === "largetext" ? <></> :
                  <TableCell 
                    key={index + '_' + subindex + '_' + (fieldindex + 1)}
                    sx={itemfield.type === "ordernr" ? { pl: 2, width: 0, whiteSpace: "nowrap" } : { pl: 2 }}
                    >
                    {renderField(field.database, subindex, itemfield, (index + '_' + subindex + '_' + fieldindex), true)}
                    </TableCell>
                ))}
                {((hasValue(field.create) && field.create !== false) || (hasValue(field.delete) && field.delete !== false) ?
                  ((hasValue(field.delete) && field.delete !== false) ?
                    <TableCell
                      key={index + '_' + subindex + '_0'}
                      sx={{ width: 0, textAlign: 'right' }}
                      >
                      <Button
                        type="submit"
                        variant="contained"
                        color='secondary'
                        name={subindex}
                        value={field.database}
                        onClick={handleDeleteRecord}
                        sx={{ borderRadius: '9px', whiteSpace: 'nowrap'  }}
                        disabled={token.payload.sub !== props?.customerData?.id_owner}
                      >
                        {loading ? (
                          <CircularProgress color='inherit' size={32} />
                        ) : (
                          tc(field?.delete, 'delete')
                        )}
                      </Button>
                    </TableCell>
                  :<></>)
                :<></>)}
              </TableRow>
              {field.fields.map((itemfield, fieldindex) => (itemfield.type === "largetext" ? 
                <TableRow 
                  key={index + '_L_' + subindex}
                  >
                  <TableCell 
                    key={index + '_' + subindex + '_' + (fieldindex + 1)}
                    colSpan={((hasValue(field.create) && field.create !== false) || (hasValue(field.delete) && field.delete !== false)) + field.fields.length}
                    sx={{ pl: 0}}
                    >
                    {renderField(field.database, subindex, itemfield, (index + '_' + subindex + '_' + fieldindex), true)}
                    </TableCell>
                </TableRow>
              : <></>))}
            </>
            ))}
        </TableBody>
      </Table>
      )
      || (field.type === "list" && table === "customer" &&
        listData?.map((item, index) => (
          renderField("list", field.database, {...field.fields, "identifier":item.identifier, "description": item.name}, index)
        ))
      )
      ||
        <Box
          className={"input-unsupported"}
          key={index}
          >
          {field.name}, UNSUPORTED TYPE: {field.type}<br/>
        </Box>
    }
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box 
        className="field-container"
            sx={{ ml: -0.5, mr: -0.5, mt: 1 }}>
        {fieldBlocks.map((block, blockIndex) => (
          <Box 
            className="field-block" 
            key={"block_"+blockIndex}>
            {block.map((field, fieldIndex) => (
              renderField("customer",0, field, fieldIndex, false)
            ))}
          </Box>
        ))}
      </Box>
      <Box sx={{ height: '37px', mt: 1, mb: 1, textAlign: 'center', position: 'relative' }}>
        <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => handlePreviousPage()}
          sx={{ borderRadius: '9px', position: 'absolute', left: 0}}
        >
          {loading ? (
            <CircularProgress color='inherit' size={32} />
          ) : (
            tc(props?.pageConfig?.button_previous,'previous_page')
          )}
        </Button>
        {props?.pageConfig?.special_button ? (
          <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => buttonAction(props?.pageConfig?.special_button?.action)}
          sx={{ borderRadius: '9px'}}
          disabled={props?.pageConfig?.special_button?.owner_only === true && token.payload.sub !== props?.customerData?.id_owner}
        >
          {tc(props?.pageConfig?.special_button?.description,'&nbsp')}
        </Button>
        ) : (
          <></>
        )
        }
        <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => handleNextPage()}
          sx={{ borderRadius: '9px', position: 'absolute', right: 0}}
        >
          {loading ? (
            <CircularProgress color='inherit' size={32} />
          ) : (
            tc(props?.pageConfig?.button_next, 'next_page')
          )}
        </Button>
      </Box>
    </ThemeProvider>
  )
}