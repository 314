import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { Button, Box, CssBaseline, Icon } from '@mui/material'
import { Person, People, Settings, PersonAdd } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getToken, getRole } from '../redux/dataslices/tokenSlice'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CreateCustomer } from "../helpers/dataHandlers/Inventarisation"

export default function DashboardPanel(props) {
  const token = useSelector(getToken)
  const navigate = useNavigate()
  const role = useSelector(getRole)
  const { t } = useTranslation()

  const onClickCreateNewCustomer = () => {
    CreateCustomer(navigate, t, token, token.payload.sub)
  }

  const onClickMyCustomers = () => {
    navigate("/customers")
  }

  const onClickAllCustomers = () => {
    navigate("/customers/all")
  }

  const onClickUserSettings = () => {
    navigate("/settings")
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box
        className="field-container"
        sx={{ ml: -0.5, mr: -0.5, mt: 1 }}>
        <Box className="field-block">
          <Button
            type="submit"
            variant="contained"
            color='logoBlue'
            className='dashboard-button'
            onClick={() => onClickCreateNewCustomer()}
          >
            <Icon>
              <PersonAdd />
            </Icon>
            &nbsp;
            {t('create_new_customer')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color='logoBlue'
            className='dashboard-button'
            onClick={() => onClickMyCustomers()}
          >
            <Icon>
              <Person />
            </Icon>
            &nbsp;
            {role === "super" || role === "admin"?t('my_customers'):t('customers')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color='logoBlue'
            className='dashboard-button'
            onClick={() => onClickUserSettings()}
          >
            <Icon>
              <Settings />
            </Icon>
            &nbsp;
            {t('settings')}
          </Button>
          {role === "super" || role === "admin"?
            <Button
              type="submit"
              variant="contained"
              color='logoBlue'
              className='dashboard-button'
              onClick={() => onClickAllCustomers()}
            >
              <Icon>
                <People />
              </Icon>
              &nbsp;
              {t('all_customers')}
            </Button>
          :<></>}
        </Box>
      </Box>
    </ThemeProvider>
  )
}