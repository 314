import { createBrowserRouter } from "react-router-dom"
import Login from "../main/login"
import App from "../main/app"
import Dashboard from "../main/dashboard"
import UserSettings from "../main/userSettings"
import Customers from "../main/customers"
import AllCustomers from "../main/allCustomers"
import Customer from "../main/customer"
import Products from "../main/products"
import Quote from "../main/quote"
import OrderList from "../main/orderList"
import Overview from "../main/overview"

export default function router() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Login />
        },
        {
            path: "quote/:customerId",
            element: <Quote />,
        },
        {
            path: "quote/:customerId/:ownerId",
            element: <Quote />,
        },
        {
            path: "orderlist/:customerId",
            element: <OrderList />,
        },
        {
            path: "orderlist/:customerId/:ownerId",
            element: <OrderList />,
        },
        {
            path: "overview/:customerId",
            element: <Overview />,
        },
        {
            path: "overview/:customerId/:ownerId",
            element: <Overview />,
        },
        {
            element: <App />,
            children: [
                {
                    path: "dashboard",
                    element: <Dashboard />
                },
                {
                    path: "settings",
                    element: <UserSettings />
                },
                {
                    path: "customers",
                    element: <Customers />
                },
                {
                    path: "customers/all",
                    element: <AllCustomers />
                },
                {
                    path: "customer/:customerId",
                    element: <Customer />,
                },
                {
                    path: "customer/:customerId/:pageNumber",
                    element: <Customer />,
                },
                {
                    path: "customer/:customerId/:pageNumber/:ownerId",
                    element: <Customer />,
                },
                {
                    path: "addhardware/:hardwareType/:customerId/:pageNumber",
                    element: <Products />,
                }
            ]
        },
    ])

    return router
}