import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ActablueLogofull from '../static/icons/logo_png_transparant.png'
import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { CssBaseline } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setDrawerOpen } from '../redux/dataslices/drawerSlice'

export default function Navbar() {
  const dispatch = useDispatch()

  const onClickIconButton = () => {
    dispatch(setDrawerOpen(true))
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="inherit" position="static">
          <Toolbar>
            <Box
              marginBottom={2}
              sx={{
                width: '100%',
                maxWidth: 185,
                textAlign: 'center',
                mt: 2
              }}
            >
              <img
                src={ActablueLogofull}
                alt='ActaBlue Logo'
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {/* Empty space */}
            </Typography>
            <div onClick={onClickIconButton}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  )
}