import Card from "../components/card"
import CustomersTable from "../components/customersTable"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getCustomers, setCustomers } from "../redux/dataslices/inventarisationSlice"
import { GetCustomersList, GetCustomerTotals, UpdateCustomer } from "../helpers/dataHandlers/Inventarisation"
import { useOutletContext } from "react-router-dom"

function Customers() {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const customers = useSelector(getCustomers)
  const [customersData, setCustomersData] = useState()
  const context = useOutletContext()

  const setChanceOfSuccess = (record_id, value) => {
    var dataToUpdate = [...customersData]
    dataToUpdate[record_id] = {...customersData[record_id], chance_of_success:value}
    setCustomersData(dataToUpdate)
  }

  const saveChanceOfSuccess = (record_id) => {
    UpdateCustomer(token, { id: customersData[record_id].id, id_owner: customersData[record_id].id_owner, chance_of_success: parseInt( customersData[record_id].chance_of_success) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setActive = (record_id, value) => {
    var dataToUpdate = [...customersData]
    dataToUpdate[record_id] = {...customersData[record_id], active:value}
    UpdateCustomer(token, { id: customersData[record_id].id, id_owner: customersData[record_id].id_owner, active: value })
    .then(setCustomersData(dataToUpdate))
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const setCustomerTotals = (payload, index) => {
    return function() {
      if(customersData && customersData.hasOwnProperty(index)) {
        var dataToUpdate = [...customersData]
        dataToUpdate[index] = {...dataToUpdate[index], total_one_off_costs_excl_vat: payload.total_one_off_costs_excl_vat, total_recurring_costs_excl_vat: payload.total_recurring_costs_excl_vat}
        setCustomersData(dataToUpdate)
      }
    }
  }

  const customerListCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'list_of_customers',
    loading: loading,
    loadingText: "loading_customers",
    data: <CustomersTable 
      data={customersData} 
      setChanceOfSuccess={setChanceOfSuccess}
      saveChanceOfSuccess={saveChanceOfSuccess}
      setActive={setActive}
      />
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      GetCustomersList(token)
        .then(response => { dispatch(setCustomers(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line
  }, [token])
  
  useEffect(() => {
    context.setCustomerData(null)
    context.setListSettingsData(null)
    if (!customers) return
    setLoading(true)
    const fetchTotalsData = async (customer_guid, index) => {
      GetCustomerTotals(token, customer_guid)
        .then(response => { dispatch(setCustomerTotals(response.data, index)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    let customersList = []
    for (const value of Object.values(customers)) {
      customersList.push(value)
      fetchTotalsData(value.id, customersList.length-1)
    }
    setCustomersData(customersList)

    // eslint-disable-next-line
  }, [customers])

  useEffect(() => {
    if (customersData && customersData !== null) {
      setLoading(false)
    }
  }, [customersData])

  return (
      <>
        <Card {...customerListCardProps} />
      </>
  )
}

export default Customers
