import React, { Fragment } from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useParams } from "react-router-dom"
import { PDFViewer, Page, Text, View, Document, Image, Link } from '@react-pdf/renderer'
import { getCustomer, setCustomer, getSubscriptions, setSubscriptions, getHardware, setHardware, getEmployees, setEmployees, getCashDrawers, setCashDrawers, getQuoteConditions, setQuoteConditions, getListSettings, setListSettings, getUserSettings, setUserSettings } from "../redux/dataslices/inventarisationSlice"
import { GetCustomer, GetUserSettings, GetSubscriptionsList, GetListSettingsList, GetAllHardwareList, GetCashDrawersList, GetEmployeesList, GetQuoteConditionsList } from "../helpers/dataHandlers/Inventarisation"
import { t, changeLanguage } from 'i18next'
import { TranslateCustom as tc } from '../helpers/translation'
import { styles } from "./overviewStyleSheet"
import companyInfo from '../quoteConfig/companyInfo.json'
import ActablueLogofull from '../static/icons/logo_png_transparant.png'

function Overview(props) {
  const {customerId} = useParams()
  const {ownerId} = useParams()
  const dispatch = useDispatch()
  const token = useSelector(getToken)
  const customer = useSelector(getCustomer)
  const hardware = useSelector(getHardware)
  const employees = useSelector(getEmployees)
  const cashDrawers = useSelector(getCashDrawers)
  const quoteConditions = useSelector(getQuoteConditions)
  const subscriptions = useSelector(getSubscriptions)
  const listSettings = useSelector(getListSettings)
  const userSettings = useSelector(getUserSettings)
  const [customerData, setCustomerData] = useState()
  const [hardwareData, setHardwareData] = useState()
  const [employeesData, setEmployeesData] = useState()
  const [cashDrawersData, setCashDrawersData] = useState()
  const [quoteConditionsData, setQuoteConditionsData] = useState()
  const [subscriptionsData, setSubscriptionsData] = useState()
  const [listSettingsData, setListSettingsData] = useState()
  
  let pagesData = []
  for(let pageNumber = 1; true; pageNumber++) {
    try {
      let pageConfig = require('../pages/'.concat(pageNumber,'.json'))
      if(pageConfig.dont_show_in_overview !== true) {
        pagesData.push(pageConfig)
      }
    }
    catch(e) {
      break
    }
  }

  const loadUserSettings = async () => {
    GetUserSettings(token)
      .then(response => { dispatch(setUserSettings(response.data)) })
      .catch(error => { })
  }

  useEffect(() => {
    const fetchCustomer = async () => {
      GetCustomer(token, customerId, ownerId)
        .then(response => { dispatch(setCustomer(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchSubscriptions = async () => {
      GetSubscriptionsList(token, customerId, ownerId)
        .then(response => { dispatch(setSubscriptions(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchListSettings = async () => {
      GetListSettingsList(token, customerId, ownerId)
        .then(response => { dispatch(setListSettings(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchHardware = async () => {
      GetAllHardwareList(token, customerId, ownerId)
        .then(response => { dispatch(setHardware(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchEmployees = async () => {
      GetEmployeesList(token, customerId, ownerId)
        .then(response => { dispatch(setEmployees(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchCashDrawers = async () => {
      GetCashDrawersList(token, customerId, ownerId)
        .then(response => { dispatch(setCashDrawers(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchQuoteConditions = async () => {
      GetQuoteConditionsList(token, customerId, ownerId)
        .then(response => { dispatch(setQuoteConditions(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    fetchCustomer()
    fetchSubscriptions()
    fetchListSettings()
    fetchHardware()
    fetchEmployees()
    fetchCashDrawers()
    fetchQuoteConditions()
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (!customer) return
    setCustomerData(customer)
    // eslint-disable-next-line
  }, [customer])
  
  useEffect(() => {
    if (!subscriptions) return
    let subscriptionsList = {}
    for (const value of Object.values(subscriptions)) {
      subscriptionsList[value.id] = value
    }
    setSubscriptionsData(subscriptionsList)

    // eslint-disable-next-line
  }, [subscriptions])

  useEffect(() => {
    if (!listSettings) return
    let listSettingsList = {}
    for (const value of Object.entries(listSettings)) {
      if(!listSettingsList.hasOwnProperty(value[1].list_name)) {
          listSettingsList[value[1].list_name] = {}
      }
      listSettingsList[value[1].list_name][value[1].identifier] = value[1]
    }
    setListSettingsData(listSettingsList)
    // eslint-disable-next-line
  }, [listSettings])
  
  useEffect(() => {
    if (!hardware) return
    let hardwareList = {}
    for (const value of Object.values(hardware)) {
      if(!hardwareList[value.hardware_type]) {
        hardwareList[value.hardware_type] = []
      }
      hardwareList[value.hardware_type].push(value)
    }
    setHardwareData(hardwareList)

    // eslint-disable-next-line
  }, [hardware])
  
  useEffect(() => {
    if (!employees) return
    let employeesList = []
    for (const value of Object.values(employees)) {
      employeesList.push(value)
    }
    setEmployeesData(employeesList)

    // eslint-disable-next-line
  }, [employees])
  
  useEffect(() => {
    if (!cashDrawers) return
    let cashDrawersList = []
    for (const value of Object.values(cashDrawers)) {
      cashDrawersList.push(value)
    }
    setCashDrawersData(cashDrawersList)

    // eslint-disable-next-line
  }, [cashDrawers])
  
  useEffect(() => {
    if (!quoteConditions) return
    let quoteConditionsList = []
    for (const value of Object.values(quoteConditions)) {
      quoteConditionsList.push(value)
    }
    setQuoteConditionsData(quoteConditionsList)

    // eslint-disable-next-line
  }, [quoteConditions])

  useEffect(() => {
    if(userSettings?.user_language) {
      changeLanguage(userSettings.user_language)
    }
  }, [userSettings])

  const RenderOverview = () => (
    <Document>
      <IndexPage />
      {pagesData ? pagesData.map((pageData, pageNumber) => (
        <OverviewPage 
          key={"page_"+pageNumber}
          pageData={pageData} 
          pageNumber={pageNumber}
          />
      )):<></>}
    </Document>
  )

  const PageHeader = () => (  
    <View fixed style={styles.header_logo}>
      <Image src={ActablueLogofull} />
    </View>
  )

  const PageFooter = () => (
    <View fixed style={styles.footer}>
      <Text style={styles.footer_left}>
      {t("overview_of", {name: customerData?.relation_name})}
      </Text>
      <Text style={styles.footer_right}>
        {companyInfo.website} / {companyInfo.telephone}
      </Text>
    </View>
  )

  const IndexPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.index_table}>
        <Text style={styles.index_header}>{t("table_of_contents")}</Text>
        {pagesData ? pagesData.map((pageData, index) => (
          <Link 
            key={"pagelink_" + index}
            src={"#page_" + index} style={styles.index_line}
            >
              {tc(pageData.title)}
            </Link>
        )):<></>}
      </View>
      <PageFooter/>
    </Page>
  )
  
  const OverviewPage = ({pageNumber, pageData}) => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
        <View style={styles.page_table}>
          <Text id={"page_"+pageNumber} style={styles.page_title}>
            {tc(pageData.title)}
          </Text>
          {pageData.fields ? pageData.fields.map((fieldConfig, index) => (
            <Fragment
              key={"field_" + index}>
              {fieldConfig.type !== "table" && (index === 0 || pageData.fields[index-1].type === "table") ? <FieldHeader/> : <></>}
              <RenderField 
                fieldConfig={fieldConfig} 
                dataSet={customerData}/>
            </Fragment>
          )):<></>}
        </View>
      <PageFooter/>
    </Page>
  )

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'), replace);
  }
  
  const hasValue = (variable) => {
    return variable !== undefined && variable !== null
  }

  const RenderField = ({fieldConfig, dataSet}) => {
    let fieldValue = dataSet?.[fieldConfig.name]
    if(fieldConfig?.type === "calculation") {
      fieldValue = fieldConfig.value ? fieldConfig.value : ""
      var matches = [...fieldConfig.value.matchAll(/\[(.*?)\]/g)]
      for (const match of matches) {
        fieldValue = replaceAll(fieldValue, match[0], hasValue(dataSet[match[1]]) && dataSet[match[1]] !== "" ? dataSet[match[1]] : "[UNDEFINED]")
      }
      if(replaceAll(fieldValue,"[UNDEFINED]","")) {
        try{
          // eslint-disable-next-line
          fieldValue = (String)(eval(replaceAll(fieldValue,"[UNDEFINED]",0)))
        }
        catch {
          fieldValue = replaceAll(fieldValue,"[UNDEFINED]","")
        }
      }
      else {
        fieldValue = replaceAll(fieldValue,"[UNDEFINED]","")
      }
    }
    if(hasValue(fieldValue) && fieldValue !== "" && hasValue(fieldConfig?.decimals)) {
      fieldValue = (Number)(fieldValue).toFixed(fieldConfig.decimals)
    }
    switch(fieldConfig?.type) {
      case "text":
      case "largetext":
      case "calculation":
        fieldValue = fieldValue ? fieldValue : (fieldConfig.default ? fieldConfig.default : "")
        return (
          <FieldData name={fieldConfig.description} value={fieldValue}/>
        )
      case "number":
      case "slider":
        fieldValue = hasValue(fieldValue) ? fieldValue : (fieldConfig.default ? fieldConfig.default : (fieldConfig.values?.min ? fieldConfig.values.min : ""))
        return (
          <FieldData name={fieldConfig.description} value={fieldValue}/>
        )
      case "toggle":
        fieldValue = hasValue(fieldValue) ? (fieldValue ? t("yes") : t("no")) : (fieldConfig.default != null ? (fieldConfig.default ? t("yes") : t("no")) : "")
        return (
          <FieldData name={fieldConfig.description} value={fieldValue}/>
        )
      case "checkbox":
        fieldValue = JSON.parse(fieldValue ? fieldValue : (fieldConfig.default ? fieldConfig.default : "[]"))
        fieldConfig?.values?.map((option) => {
          fieldValue?.map((val, val_index) => {
            if(option.value === val) {
              fieldValue[val_index] = tc(option.description)
            }
            return null
          })
          return null
        })
        return (
          <FieldData name={fieldConfig.description} value={fieldValue.length > 1 ? fieldValue.reduce((result, item) => <>{result}, {item}</>) : fieldValue}/>
        )
      case "select":
        fieldValue = fieldValue ? fieldValue : (fieldConfig.default ? fieldConfig.default : "")
        if(fieldConfig.values === "language_list") {
          fieldValue = fieldValue ? t("language_select_" + fieldValue) : ""
        }
        else {
          fieldConfig?.values?.map((option) => {
            if(option.value === fieldValue) {
              fieldValue = tc(option.description)
            }
            return null
          })
        }
        return (
          <FieldData name={fieldConfig.description} value={fieldValue}/>
        )
      case "list":
        return (listSettingsData?.[fieldConfig.database] ? 
          Object.entries(listSettingsData[fieldConfig.database]).map((value) => (value[0] !== "value" ?
            <RenderField 
            key={"listitem_" + value[0]}
            fieldConfig={{...fieldConfig?.fields, description: subscriptionsData?.[value[0]]?.subscription_name}}
            dataSet={value[1]}
            />
        :<></>)):<></>)
      case "table":
        let dataSet = []
        switch(fieldConfig.database) {
          case "hardware":
            dataSet = hardwareData?.[fieldConfig.database_filter]
            break
          case "employees":
            dataSet = employeesData
            break
          case "cashdrawers":
            dataSet = cashDrawersData
            break
          case "quoteconditions":
            dataSet = quoteConditionsData
            break
          default:
            break
        }
        return(dataSet && dataSet.length > 0 ? 
          dataSet.map((row, row_id) => (
            <Fragment
              key={"tableitem_" + row_id}>
              <FieldHeader/>
              {fieldConfig.fields.map((field, field_id) => (
                <RenderField 
                  key={"tableitem_" + row_id + "_" + field_id}
                  fieldConfig={field}
                  dataSet={row}
                  />
              ))}
            </Fragment>
          ))
        :
        <Fragment
          key={"tableitem_none"}>
          <FieldHeader/>
          {fieldConfig.fields.map((field, field_id) => (
            <RenderField 
              key={"tableitem_none_" + field_id}
              fieldConfig={{description: field["description"], name: "0", type: "text"}}
              dataSet={["-"]}
              />
          ))}
        </Fragment>)
      case "ordernr":
        return (<></>)
      case "label":
        return (<></>)
      default:
        return (
          <Text>
            {fieldConfig?.name}, UNSUPORTED TYPE: {fieldConfig?.type}
          </Text>
        )
    }
  }

  const FieldHeader = () => (
    <View style={styles.header}>
    <Text style={styles.header_170_L}>
      {t("field")}
    </Text>
    <Text style={styles.header_350_L}>
      {t("value")}
    </Text>
  </View>
  )

  const FieldData = ({name, value}) => (
    <View style={styles.line}>
      <Text style={styles.line_170_L}>
        {tc(name)}
      </Text>
      <Text style={styles.line_350_L}>
        {hasValue(value) && value !== "" ? value : "-"}
      </Text>
    </View>
  )

  if (!userSettings) {
    loadUserSettings()
  }

  return (
    <>
      <style>{`
        html {
          overflow: hidden
        }
      `}</style>
      <PDFViewer style={{width:"100%", height:"100%", border: 0, margin: 0, padding: 0}}>
        <RenderOverview />
      </PDFViewer>
    </>
  )
}

export default Overview